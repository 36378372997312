/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Typography,
  Modal,
  Button,
} from "antd";

import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

type HeaderProps = {
  onPress: any;
  name: string;
  subName: string;
  handleSidenavColor: any;
  handleSidenavType: any;
  handleFixedNavbar: any;
};

const Header: React.FC<HeaderProps> = () => {

  const safety = [
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2L3 4.5V9C3 13.0218 5.95228 16.6087 10 17.8299C14.0477 16.6087 17 13.0218 17 9V4.5L10 2Z"
        fill="#111827"
      />
      <path
        d="M8.5 10.5L7 9L6 10L8.5 12.5L14 7L13 6L8.5 10.5Z"
        fill="white"
      />
    </svg>
  ];

  const bell = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
        fill="#111827"
      ></path>
      <path
        d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
        fill="#111827"
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill="#111827"
      ></path>
    </svg>,
  ];

  const { Title } = Typography;
  const [organizationName, setOrganizationName] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem('jwtToken');
    if (token) {
      const decodedToken: { organizationName: string } = jwtDecode(token);
      setOrganizationName(decodedToken.organizationName);
    }
  }, []);

  // Decode the JWT token to extract the organizationId
  const token = localStorage.getItem('jwtToken');
  if (!token) {
    throw new Error('No JWT found in localStorage');
  }

  const decodedToken: any = jwtDecode(token);  
  const organization = decodedToken.organization
  const encodedOrganizationId = btoa(organization);
  const [isAiSafetyModalVisible, setIsAiSafetyModalVisible] = useState(false); // State for AI Safety Commitment Modal
  const handleAiSafetyModalOk = () => {
    setIsAiSafetyModalVisible(false);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Title level={4} style={{ margin: 0 }}>{organizationName}</Title>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Link to="/sign-out" className="btn-sign-in">
            {profile}
            <span>Sign Out</span>
          </Link>
          <Link to={`/incident/${encodedOrganizationId}`} className="btn-sign-in">
            {bell}
            <span>Report an Incident</span>
          </Link>
          <Button type="link" onClick={() => setIsAiSafetyModalVisible(true)}>
            {safety}
            <span>AI Safety Commitment</span>
          </Button>
        </Col>
      </Row>
      <Modal
        title="AI Safety Commitment"
        visible={isAiSafetyModalVisible}
        onOk={handleAiSafetyModalOk}
        onCancel={handleAiSafetyModalOk}
        okText="OK"
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button

      >
        <p>
          Our commitment to AI safety is paramount. We ensure that all AI systems
          deployed on this platform adhere to the highest standards of safety and
          ethical guidelines. We continuously monitor and evaluate our AI systems
          to prevent any unintended consequences and to promote transparency and
          accountability.
        </p>
      </Modal>
    </>
  );
}

export default Header;
