import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();


  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleActivity = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const jwt = localStorage.getItem('jwtToken');
        if (jwt) {
          const jwtPayload = JSON.parse(atob(jwt.split('.')[1]));
          const exp = jwtPayload.exp * 1000;
          if (Date.now() >= exp ) {
            localStorage.removeItem('jwtToken');
            navigate('/login');
            message.info('You have been signed out due to inactivity.');
          }
        }
      }, 15 * 60 * 1000); 
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    handleActivity(); // Initialize the timer

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  });

  return <div>{children}</div>;
};

export default AppLayout;