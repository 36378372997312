import { useAiChatApi } from '@nlux/react';
import React, { createContext, useContext, useState } from 'react';

interface ChatContextType {
  openChatWithQuestion: (question: string) => void;
  isOpen: boolean;
  currentQuestion: string | null;
  closeChat: () => void;
  triggerMessage: (message: string) => void; 
  api: any;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<string | null>(null);
  const [messageCallback] = useState<((message: string) => void) | null>(null);

  const openChatWithQuestion = (question: string) => {
    setCurrentQuestion(question);
    setIsOpen(true);
  };

  const closeChat = () => {
    setIsOpen(false);
    setCurrentQuestion(null);
  };

  const triggerMessage = (message: string) => {
    if (messageCallback) {
      messageCallback(message);
    }
  };

  const api = useAiChatApi();

  return (
    <ChatContext.Provider value={{ 
      openChatWithQuestion, 
      isOpen, 
      currentQuestion, 
      closeChat,
      triggerMessage,
      api
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 