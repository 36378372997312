/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Home from "./pages/Home";
import Employees from "./pages/Employees";
import Training from "./pages/Training";
import MyTrainings from "./pages/MyTrainings";
import Incidents from "./pages/Incidents";
import AccessRequests from "./pages/AccessRequests";
import Hipaa from "./pages/HIPAA";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import ProtectedRoute from './components/ProtectedRoute';
import AuthContext from './contexts/AuthContext'; // Import AuthContextProvider
import IncidentReport from './pages/IncidentReport';
import IncidentsPublish from './pages/IncidentNoticePublish';
import TrainingViewer from './pages/TrainingViewer';
import Assessments from './pages/Assessments';
import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { ChatProvider } from './context/ChatContext';
import AppLayout from './components/layout/AppLayout';


function App() {

  const location = useLocation()

  useEffect(() => {
    localStorage.setItem('lastVisitedRoute', location.pathname);
  }, [location]);

  return (
    <ChatProvider>
      <AuthContext>
        <Routes>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-out" element={<SignOut />} />
          <Route path="/incident/:id" element={<IncidentReport />} /> 
          
          <Route path="/dashboard" element={<AppLayout><ProtectedRoute element={<Home />} /></AppLayout>} />
          <Route path="/hipaa" element={<ProtectedRoute element={<Hipaa />} />} />
          <Route path="/employees" element={<ProtectedRoute element={<Employees />} />} />
          <Route path="/incidents" element={<ProtectedRoute element={<Incidents />} />}/>
          <Route path="/assessments" element={<ProtectedRoute element={<Assessments />} />} />
          <Route path="/accessRequests" element={<ProtectedRoute element={<AccessRequests />} />} />
          <Route path='/incidents/publish' element={<ProtectedRoute element={<IncidentsPublish />} />}/>
          <Route path="/training" element={<ProtectedRoute element={<Training />} />} />
          <Route path="/mytrainings/:id" element={<ProtectedRoute element={<TrainingViewer />} />} />
          <Route path="/mytrainings" element={<ProtectedRoute element={<MyTrainings />} />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </AuthContext>
    </ChatProvider>
  );
}

export default App;
