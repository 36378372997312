import { PersonaOptions } from '@nlux/react';
import logo from "../assets/images/logo.svg";

const userAvatar = [
    <svg
      width="200"
      height="200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
    </svg>,
  ];

export const personas: PersonaOptions = {
    assistant: {
        name: 'AI Co-pilot',
        tagline: 'Here to help!',
        avatar: logo,
    },
    user: {
        name: 'User',
        avatar: userAvatar[0],
    }
};

export const miniPersonas: PersonaOptions = {
  assistant: {
      name: 'AI Co-pilot',
      avatar: logo,
  },
  user: {
      name: 'User',
      avatar: userAvatar[0],
  }
};